<template>
    <div class="col-md-8">
        <seccion-datos titulo="Plantillas de baremos" :botonGuardar="false">
            <div style="display: flex; gap: 20px; align-items: center; flex-wrap: wrap;">
                <input v-model="nombreNuevaPlantilla" style="width: 300px;" class="form-control" placeholder="Nombre nueva plantilla" type="text">
                <MultiSelect v-model="tipoDañoSelec" optionValue='id' :options="tiposDaño" optionLabel="danyo" placeholder="Seleccione tipo de daño"
                :maxSelectedLabels="3" style="width: 350px;"/>
                <button class="btn btn-primary" @click="crearPlantilla">Crear</button>
            </div>
            <div style="width: 100%; margin-left: 0; margin-top: 20px" class="row" v-if="plantillas && plantillas.length > 0">
                <div class="table-responsive">
                    <table style="width: 100%;" class="table">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Tipo daño</th>
                                <th>Ver</th>
                                <th>Eliminar</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="plantilla in plantillas" :key="plantilla.id">
                                <td>{{ plantilla.nombre }}</td>
                                <td>{{ plantilla.tipos_danyos_str }}</td>
                                <td>
                                    <button class="btn btn-sm btn-light" style="border: 1px solid grey;" @click="verPlantilla(plantilla)">Ver</button>
                                </td>
                                <td>
                                    <button class="btn btn-sm btn-light" style="border: 1px solid grey;" @click="eliminarPlantilla(plantilla)">Eliminar</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>            
            </div>
        </seccion-datos>
        <Dialog :style="{ width: '65rem' }" @hide="cerrarVista" :header="'Plantilla '+plantillaVista.nombre" modal
        v-model:visible="vista">
            <div style="display: grid; gap: 20px;">
                <div style="display: flex; gap: 20px; align-items: center">
                    <div style="display: flex; gap: 10px; align-items: center">
                        <span>Nombre</span>
                        <b>{{ plantillaVista.nombre }}</b>
                    </div>
                    <div style="display: flex; gap: 10px; align-items: center">
                        <span>Tipo daño:</span>
                        <MultiSelect v-model="tipoDañoSelecPlantilla" optionValue='id' :options="tiposDaño" optionLabel="danyo" placeholder="Seleccione tipo de daño"
                        :maxSelectedLabels="3" style="width: 350px;"/>
                    </div>
                </div>
                <div style="display: flex; gap: 15px; align-items: center">
                    <select class="form-control" v-model="trabajoSelec">
                        <option value="">----Seleccionar trabajo----</option>
                        <option v-for="trabajo in trabajosBaremo" :disabled="plantillaVista.trabajosAnadidos.includes(trabajo.idtrabajo)" :key="trabajo.idtrabajo" :value="trabajo">{{ trabajo.nombre }}</option>
                    </select>
                    <div>
                        <button class="btn btn-light" style="border: 1px solid grey;" @click="agregarTrabajoTemporal()">Agregar</button>
                    </div>
                </div>
                <div v-if="Object.values(plantillaVista.trabajos).length>0 || mostrarTemporal" class="table-responsive">
                    <table class="table table-sm">
                        <thead>
                            <tr>
                                <th>Código</th>
                                <th>Nombre del trabajo</th>
                                <th>Especialidad</th>
                                <th>Cantidad</th>
                                <th>Descuento</th>
                                <th>IVA%</th>
                                <th>Destinatario factura</th>
                                <th>Destinatario trabajo</th>
                                <th>Eliminar</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="trabajo in Object.values(plantillaVista.trabajos)" :key="trabajo.trabajos_companyia_id">
                                <td>{{ trabajo.codigo }}</td>
                                <td>{{ trabajo.nombre }}</td>
                                <td>{{ trabajo.especialidad }}</td>
                                <td>{{ trabajo.cantidad }}</td>
                                <td>{{ trabajo.descuento }}</td>
                                <td>{{ trabajo.iva }}</td>
                                <td>{{ trabajo.destinatario_factura_trabajo }}</td>
                                <td>{{ trabajo.destinatario_trabajo }}</td>
                                <td>
                                    <button class="btn btn-sm btn-light" style="border: 1px solid grey;" @click="eliminarTrabajoPlantilla(trabajo.trabajos_companyia_id)">Eliminar</button>
                                </td>
                            </tr>
                            <tr style="background-color: aqua;" v-if="mostrarTemporal">
                                <td>{{ temporal.codigo }}</td>
                                <td>{{ temporal.nombre }}</td>
                                <td></td>
                                <td>
                                    <input type="number" class="form-control form-control-sm" v-model="temporal.cantidad">
                                </td>
                                <td>
                                    <input type="number" class="form-control form-control-sm" v-model="temporal.descuento">
                                </td>
                                <td>
                                    <input type="number" class="form-control-sm form-control" v-model="temporal.iva">
                                </td>
                                <td>
                                    <select v-model="temporal.destinatario_factura" class="form-control form-control-sm">
                                        <option value="Compañia">Compañía</option>
                                        <option value="Cliente">Cliente</option>
                                    </select> 
                                </td>
                                <td>{{ temporal.destinatario }}</td>
                                <td>
                                    <button class="btn btn-sm btn-light" style="border: 1px solid grey;" @click="agregarTrabajoDefinitivo()">Agregar</button>
                                    <button class="btn btn-sm btn-light" style="border: 1px solid grey;" @click="eliminarTemporal">Eliminar</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </Dialog>
    </div>
</template>
<script>
import MultiSelect from 'primevue/multiselect';
import { PwgsApi } from '../../../../services/PwgsApi';
export default {
    components: {
        MultiSelect,
    },
    props: {
        datos: {
            type: Object
        }
    },
    data() {
        return {
            trabajoSelec:'',
            tipoDañoSelecPlantilla:[],
            tipoDañoSelec:[],
            tiposDaño: [],
            plantillas: [],
            nombreNuevaPlantilla:'',
            vista:false,
            plantillaVista:'',
            trabajosBaremo:[],
            mostrarTemporal:false,
            temporal: '',
        }
    },
    methods: {
        async eliminarTrabajoPlantilla(idtrabajo){
            const api = new PwgsApi();
            try{
                await api.delete('companias/'+this.datos.id+'/plantillas-baremo/'+this.plantillaVista.id+'/trabajos/'+idtrabajo);
                this.recargarPlantilla(this.plantillaVista.id);
            }catch(e){
                this.$toast.add({ severity: 'error', summary: e, detail: e, life: 5000 });
            }
        },
        cerrarVista(){
            this.trabajoSelec = '';
            this.eliminarTemporal();
        },
        async agregarTrabajoDefinitivo(){
            const api = new PwgsApi();
            try{
                await api.post('companias/'+this.datos.id+'/plantillas-baremo/'+this.plantillaVista.id+'/trabajos/'+this.temporal.idtrabajo, 
                {"cantidad":this.temporal.cantidad, "descuento": this.temporal.descuento, "iva": this.temporal.iva, 
                "destinatario_factura": this.temporal.destinatario_factura, "destinatario_trabajo": this.temporal.destinatario});
                this.recargarPlantilla(this.plantillaVista.id);
            }catch(e){
                this.$toast.add({ severity: 'error', summary: e, detail: e, life: 5000 });
            }
        },
        async recargarPlantilla(idplantilla){
            const api = new PwgsApi();
            var plant = await api.get('companias/'+this.datos.id+'/plantillas-baremo/'+idplantilla+'/trabajos');
            if(plant.tipos_danyos){
                console.log('plantillas2');
                plant.tipos_danyos_str = '';
                for(let tipoDanyo of Object.values(plant.tipos_danyos)){
                    console.log('plantillas3');
                    plant.tipos_danyos_str += tipoDanyo.nombre + ' ';
                }
            }
            for(let index in this.plantillas){
                if(this.plantillas[index].id == idplantilla){
                    this.plantillas[index] = plant;
                    this.verPlantilla(plant);
                    this.eliminarTemporal();
                    this.trabajoSelec = '';
                    break;
                }
            }
        },
        eliminarTemporal(){
            this.mostrarTemporal = false;
            this.temporal = '';
        },
        async agregarTrabajoTemporal(){
            const api = new PwgsApi();
            const datos = await api.get('companias/'+this.datos.id+'/plantillas-baremo/'+this.plantillaVista.id+'/trabajos/temporal');
            console.log('trabajotempo', this.trabajoSelec, datos);
            if(this.trabajoSelec.destinatario==''){
                this.trabajoSelec.destinatario = 'compañia';
            }
            this.temporal = this.trabajoSelec;
            this.temporal.descuento = parseFloat(datos.descuento);
            this.temporal.iva = parseFloat(datos.iva);
            this.temporal.cantidad = 1;
            this.temporal.destinatario_factura = datos.destinatario_factura;
            this.mostrarTemporal = true;
        },
        async verPlantilla(plantilla){
            try{
                const api = new PwgsApi();
                this.trabajosBaremo = await api.get('companias/'+this.datos.id+'/plantillas-baremo/trabajos');
                var ids = [];
                for(let danyos of Object.values(plantilla.tipos_danyos)){
                    ids.push(danyos.id);
                }
                this.tipoDañoSelecPlantilla = ids;
                var trabajosAnadidosAux = Object.keys(plantilla.trabajos);
                plantilla.trabajosAnadidos = trabajosAnadidosAux;
                this.plantillaVista = plantilla;
                this.vista = true;
            }catch(e){
                this.$toast.add({ severity: 'error', summary: e, detail: e, life: 5000 });
            }
        },
        async eliminarPlantilla(plantilla){
            const api = new PwgsApi();
            try{
                await api.delete('companias/'+this.datos.id+'/plantillas-baremo/'+plantilla.id);
                this.obtenerPlantillas();
            }catch(e){
                this.$toast.add({ severity: 'error', summary: e, detail: e, life: 5000 });
            }
        },
        async crearPlantilla(){
            const api = new PwgsApi();
            if(!this.nombreNuevaPlantilla){
                alert('Seleccione un nombre para la nueva plantilla.');
                return;
            }
            if(this.tipoDañoSelec.length == 0){
                alert('Seleccione un tipo de daño.');
                return;
            }
            try{
                await api.post('companias/'+this.datos.id+'/plantillas-baremo', 
                    {'nombre':this.nombreNuevaPlantilla, 'companyias_id':this.datos.id, 'tipos_danyo_id':this.tipoDañoSelec});
                this.obtenerPlantillas();
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Fecha cambiada correctamente', life: 2000 });
            }catch(e){
                this.$toast.add({ severity: 'error', summary: 'Error', detail: e, life: 5000 });
            }
        },
        cargarDatos(){
            this.obtenerTiposDanos();
            this.obtenerPlantillas();
        },
        async obtenerTiposDanos(){
            const api = new PwgsApi();
            const resp = await api.get('tipos-dano');
            this.tiposDaño = resp.datos;
        },
        async obtenerPlantillas(){
            const api = new PwgsApi();
            const resp = await api.get('companias/'+this.datos.id+'/plantillas-baremo');
            var plantillass = resp.datos;            
            var plantillasaux = [];
            for(let plantilla of plantillass){
                console.log('plantillas1', plantilla);
                var plant = await api.get('companias/'+this.datos.id+'/plantillas-baremo/'+plantilla.id+'/trabajos');
                if(plant.tipos_danyos){
                    console.log('plantillas2');
                    plant.tipos_danyos_str = '';
                    for(let tipoDanyo of Object.values(plant.tipos_danyos)){
                        console.log('plantillas3');
                        plant.tipos_danyos_str += tipoDanyo.nombre + ' ';
                    }
                }
                plantillasaux.push(plant);
            }
            this.plantillas = plantillasaux;
            console.log('plantillas',this.plantillas);
        },
    },
    mounted() {
        this.cargarDatos();
    },
    watch:{
        id(){
            this.cargarDatos();
        },
        datos(){
            this.cargarDatos();
        }
    }
}
</script>